export const gasCheckTableColumns = [
  {
    title: '序号',
    key: 'index',
    width: 48,
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    key: 'site_name'
  },
  {
    title: '加油机编号',
    key: 'factory_num'
  },
  {
    title: '下次检查时间',
    key: 'last_time'
  }
  // {
  //   title: '状态',
  //   key: 'status',
  //   extra: ['未检查'],
  //   extraType: 'text'
  // }
];

export const lockMachineTableColumns = [
  {
    title: '序号',
    key: 'index',
    width: 48,
    align: 'center',
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    key: 'site_name',
    width: 260
  },
  {
    title: '加油机名称',
    key: 'tanker_name'
    // width: 100
  },
  {
    title: '异常条数',
    key: 'error_count',
    // width: 100,
    sort: true,
    align: 'center'
  },
  {
    title: '锁机状态',
    key: 'status',
    // width: 100,
    align: 'center',
    extraType: 'text',
    scopedSlots: {
      customRender: 'status'
    },
    extra: ['未锁机', '已锁机']
  }
];

export const smartCheckUpTableColumns = [
  {
    title: '序号',
    key: 'index',
    width: 48,
    align: 'center',
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    key: 'site_name',
    width: 160
  },
  {
    title: '加油机名称',
    key: 'tanker_name',
    width: 100
  },
  {
    title: '巡检结果',
    key: 'result',
    width: 80,
    align: 'center'
  },
  {
    title: '巡检时间',
    key: 'time',
    // width: 80,
    align: 'center'
  }
];

export const lockMachineRankColumns = [
  {
    title: '序号',
    key: 'index',
    width: 48,
    align: 'center',
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    key: 'site_name',
    minWidth: 150,
    align: 'center'
  },
  {
    title: '诚信等级',
    key: 'level',
    minWidth: 80,
    align: 'center'
  },
  {
    title: '报警数量',
    key: 'alarm',
    minWidth: 80,
    align: 'center'
  }
  // {
  //   title: '报警数量',
  //   key: 'count',
  //   minWidth: 80,
  //   align: 'center'
  // },
  // {
  //   title: '占比',
  //   key: 'percent',
  //   minWidth: 80,
  //   align: 'center'
  // }
];

export const alarmsList = [
  {
    name: '设备离线',
    key: 'tax_count',
    color: '#ff2121',
    imgSrc: require('@/assets/alarm-machine.png'),
    path: '/alr/dvc_off'
  },
  {
    name: '端口离线',
    key: 'port_count',
    color: '#FFB61E',
    imgSrc: require('@/assets/alarm-port.png'),
    path: '/alr/tax_off'
  },
  // {
  //   name: '液位仪离线',
  //   key: 'meter_count',
  //   color: '#0AA344',
  //   imgSrc: require('@/assets/icon_change_count.png'),
  //   path: '/alr/level_gauge_abno'
  // },
  {
    name: '监控微处理器变更',
    key: 'control_count',
    color: '#8D4BBB',
    imgSrc: require('@/assets/alarm-cpu-changed.png'),
    path: '/alr/tax_chg'
  },
  {
    // name: '油罐温度报警',
    name: '编码器变更',
    key: 'temp_count',
    color: '#8D4BBB',
    imgSrc: require('@/assets/alarm-encoder.png'),
    path: '/alr/ecdr_chg'
  },
  {
    name: '防作弊未开启',
    key: 'port_status',
    color: '#0095D9',
    imgSrc: require('@/assets/alarm-anti-cheat.png'),
    path: '/ncheat'
  }
];
