<template>
  <div class="statistic-meta" @click="handlePush">
    <div class="img-box" :style="{ borderColor: color }">
      <!-- <template v-if="img"> -->
      <img :src="imgSrc" alt="" :style="{ width: '64px', fill: color }" />
      <!-- {{ imgSrc }} -->
      <!-- </template> -->
      <!-- <slot v-else name="meta" /> -->
    </div>
    <div class="statistic-body">
      <span class="statistic-title">{{ title }}</span>
      <div class="statistic-content">{{ value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    value: [Number, String],
    img: String,
    color: String,
    imgSrc: String,
    path: String
  },
  methods: {
    handlePush() {
      this.path &&
        this.$store.dispatch('app/setCurrentMenu', this.path).then(() => {
          this.$router.push({
            path: this.path,
            query: {
              status: '0'
            }
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles.scss';
.statistic-meta {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 26px 24px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.5s ease-out;

  &:not(:first-child) {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 60%;
      background: radial-gradient(circle, rgba(#1f43a6, 1), rgba(#1f43a6, 0));
      opacity: 0.4;
    }
  }
  .img-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    background: #f1f2f8;
    // border: 1px dashed rgba($--color-primary, 1);

    & + .statistic-body {
      margin-left: 24px;
    }
  }
  .statistic-title {
    font-size: 14px;
    opacity: 0.6;
    letter-spacing: 1px;
    white-space: nowrap;
    & + .statistic-content {
      margin-top: 7px;
      font-size: 28px;
      // font-weight: bold;
      color: $--color-primary;
    }
  }
}
</style>
