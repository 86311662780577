<template>
  <div @mouseleave="mouseLeave" @mousemove="mouseMove" class="progress-item">
    <p class="site_name">{{ item.site_name }}</p>
    <div @mouseleave="mouseLeave" @mousemove="mouseMove">
      <el-progress
        :percentage="percentage"
        :color="index <= 2 ? colors[index] : colors[3]"
        :show-text="false"
        :stroke-width="14"
      ></el-progress>
    </div>
    <div
      class="show"
      v-show="visible"
      :style="{
        top: Top,
        left: Left
      }"
    >
      <div>{{ item.site_name }}</div>
      <div>
        <span
          class="circle"
          :style="{
            background: index <= 2 ? colors[index] : colors[3]
          }"
        ></span
        >销量：{{ item.oil_sale }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    index: Number,
    firstValue: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      colors: ['#F2AF41', '#B2C9DA', '#E7AA5B', '#01ADBE'],
      Top: 0,
      Left: 0,
      visible: false,
      percentage: 0
    };
  },
  created() {
    this.percentage = ((this.item.oil_sale * 1) / this.firstValue) * 100;
  },
  methods: {
    mouseLeave() {
      this.visible = false;
    },
    mouseMove(val) {
      this.visible = true;
      // console.log(val);
      this.Top = val.clientY + 5 + 'px';
      this.Left = val.clientX + 5 + 'px';
    }
  }
};
</script>

<style scoped lang="scss">
.progress-item {
  position: relative;

  &:nth-child(1) p {
    margin: 0;
    padding: 20px 0 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.show {
  background: rgba(51, 51, 51, 0.7);
  position: fixed;
  color: #fff;
  z-index: 10;
  border-radius: 3px;
  padding: 5px;
  min-width: 160px;
  font-size: 15px;

  .circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 8px 0;
  }
}
</style>
