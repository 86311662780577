<template>
  <div class="panel">
    <div class="panel-header">
      <span v-if="!!title" class="panel-header__title">
        {{ title }}
      </span>
      <span @click="handlePush" class="panel-header__more" v-if="path">
        <!-- 更多 -->
        <i title="更多" class="el-icon-arrow-right"></i>
      </span>
    </div>
    <div class="panel-content" style="margin-top: 10px;">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    path: String
  },
  methods: {
    handlePush() {
      this.path &&
        this.$store.dispatch('app/setCurrentMenu', this.path).then(() => {
          this.$router.push(this.path);
        });
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/styles';
.panel {
  width: 100%;
  padding: 8px 16px;
  box-sizing: border-box;
  background: #fff;
  position: relative;
  border-radius: 8px;
  transition: all 0.5s ease-out;
  &-header {
    color: #333;
    &__title {
      position: relative;
      font-weight: 700;
      line-height: 35px;
      padding-left: 16px;
      background: linear-gradient(
          to right,
          $--color-primary 0,
          $--color-primary 30px
        )
        no-repeat;
      background-size: 50px 100%;
      // &::before {
      //   content: '';
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   bottom: 0;
      //   width: 60px;
      //   height: 100%;
      //   z-index: -1;
      // }
      // &::after {
      //   content: '';
      //   display: block;
      //   width: 20px;
      //   height: 2px;
      //   background: $--color-primary;
      // }
    }

    &__more {
      position: absolute;
      right: 10px;
      top: 10px;
      line-height: 37px;
      cursor: pointer;
    }
  }
  &-header + &-content {
    margin-top: 4px;
  }
}
</style>
