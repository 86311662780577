<template>
  <div :class="className" :style="{ height: setHeight }" @click="handlePush">
    <div class="statistic-img">
      <img class="img" :src="src" />
    </div>
    <div class="statistic">
      <div class="statistic-header">
        <div class="statistic-header__title">{{ title }}</div>
      </div>
      <div class="statistic-content">
        {{ value }}
      </div>
    </div>
  </div>
</template>

<script>
import classnames from 'classnames';

export default {
  props: {
    title: String,
    value: [Number, String],
    height: {
      type: [Number, String],
      default: 135
    },
    src: String,
    to: [String, Object]
  },
  computed: {
    setHeight() {
      if (Number(this.height)) {
        return this.height + 'px';
      } else {
        return this.height;
      }
    },
    className() {
      return classnames('statistic-wrap', {
        pointer: !!this.to
      });
    }
  },
  methods: {
    handlePush() {
      this.to &&
        this.$store.dispatch('app/setCurrentMenu', this.to).then(() => {
          this.$router.push(this.to);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles';

.statistic {
  &-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 16px;
    box-sizing: border-box;
    background: #fff;
    transition: all 0.5s ease-out;
    border-radius: 8px;
    &:hover {
      box-shadow: 5px 5px 8px rgba(#000, 0.4);
    }
    &.pointer {
      cursor: pointer;
    }
  }
  &-header {
    font-size: 20px;
    &__title {
      color: #444;
      // line-height: 40px;
      margin-bottom: 16px;
      white-space: nowrap;
    }
  }
  &-content {
    color: $--color-primary;
    font-size: 34px;
  }
  &-img {
    // position: absolute;
    // right: 16px;
    // bottom: 30px;
    width: 80px;
    height: 80px;
    text-align: right;
    margin-right: 40px;
    .img {
      display: inline-block;
      height: 100%;
      user-select: none;
    }
  }
}
</style>
