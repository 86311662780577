<template>
  <div class="home-page">
    <!-- <el-row type="flex" justify="space-between" :gutter="16"> -->
    <el-row :gutter="16">
      <div style="display: flex">
        <el-col :md="8">
          <Statistic
            title="加油站数量(家)"
            :value="basicData.site_count || 0"
            :src="station_img"
            to="/ginfo/gas"
          />
        </el-col>
        <el-col :md="8">
          <Statistic
            title="加油机数量(个)"
            :value="basicData.tanker_count || 0"
            :src="machine_img"
            to="/ginfo/machine"
          />
        </el-col>
        <el-col :md="8">
          <Statistic
            title="加油枪数量(个)"
            :value="basicData.tank_count || 0"
            :src="gun_img"
            to="/ginfo/tax"
          />
        </el-col>
      </div>
      <div style="padding: 0 8px">
        <el-col :md="24" class="alarm-meta">
          <StatisticMeta
            v-for="alarm in alarms"
            :key="alarm.name"
            :title="alarm.name"
            :color="alarm.color"
            :imgSrc="alarm.imgSrc"
            :value="basicWarnData[alarm.key] || 0"
            :path="alarm.path"
          >
          </StatisticMeta>
        </el-col>
      </div>
      <el-col :sm="24" :md="12">
        <Panel title="历史油价">
          <div
            class="echart-container"
            ref="echart-pie"
            :style="{ height: `${lineHeight}px` }"
          />
        </Panel>
      </el-col>
      <el-col :sm="24" :md="11" v-if="false">
        <Panel title="销量排行TOP7" style="margin-top: 16px">
          <div :style="{ height: `${lineHeight}px` }">
            <div
              class="sales_item"
              :style="{ height: `${lineHeight}px` }"
              v-if="salesRankData.length > 0"
            >
              <div
                v-for="(item, index) in salesRankData"
                :key="item.sitem_id"
                class="items"
              >
                <progress-item
                  :item="item"
                  :index="index"
                  :firstValue="salesRankData[0].oil_sale * 1"
                ></progress-item>
              </div>
            </div>
            <div
              class="sales_item"
              :style="{ height: `${lineHeight}px`, color: '#aaa' }"
              v-else
            >
              不存在加油站！
            </div>
          </div>
        </Panel>
      </el-col>
      <el-col :sm="24" :md="12">
        <Panel title="加油站诚信等级">
          <default-table
            pagination="none"
            :dataSource="creditRatingData"
            :columns="lockMachineRankColumns"
            :height="tableHeight"
            highlight
            size="mini"
            :loading="tableLoadingA"
          >
            <template #index="{ scope }">{{ scope.$index + 1 }}</template>
          </default-table>
        </Panel>
        <Panel
          title="计量超差数量显示"
          style="margin-top: 16px"
          :path="'/gap_lock/list'"
        >
          <default-table
            pagination="none"
            :dataSource="smartCheckData"
            :columns="lockMachineTableColumns"
            :height="tableHeight"
            highlight
            size="mini"
            :loading="tableLoadingC"
          >
            <template #index="{ scope }">{{ scope.$index + 1 }}</template>
            <template #status="{ scope }">
              <div class="primary" v-if="scope.row.status === 0">
                未锁机
              </div>
              <div class="danger" v-else>已锁机</div>
            </template>
          </default-table>
        </Panel>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Panel from './components/Panel';
import DefaultTable from '@/components/DefaultTable/new';
import StatisticMeta from './components/Meta';
import ProgressItem from './components/progress';
import Statistic from '@/components/Statistic';
import echart from '@/components/Chart/utils';
import Resize from '@/components/Chart/resize';
import {
  getSiteData,
  getLockMachines,
  getWarningCountData,
  getSalesRank,
  // getAlarmCountRank,
  getCreditRating,
  getHistoryPrice
} from '@/api/home';
import station_img from '@/assets/tag_station.png';
import machine_img from '@/assets/tag_machine.png';
import gun_img from '@/assets/tag_gun.png';

import {
  gasCheckTableColumns,
  lockMachineTableColumns,
  smartCheckUpTableColumns,
  lockMachineRankColumns,
  alarmsList
} from './home.d';
import mixins from '@/mixins';

export default {
  mixins: [mixins],
  name: 'Home',
  components: {
    Panel,
    DefaultTable,
    Statistic,
    StatisticMeta,
    ProgressItem
  },
  data() {
    // const self = this;
    return {
      tableLoadingA: true,
      tableLoadingB: true,
      tableLoadingC: true,
      station_img,
      machine_img,
      gun_img,
      basicData: {
        //加油站基础数据
        site_count: 0, // 加油站数量
        tanker_count: 0, // 加油机数量
        port_count: 0, // 油罐数量
        port_status: 0,
        alarm_count: 0
      },
      alarms_value: {},
      alarms: alarmsList,
      site_name: '', //加油站名称
      gasCheckTableColumns,
      lockMachineTableColumns,
      smartCheckUpTableColumns,
      lockMachineRankColumns,
      basicWarnData: {}, // 警告异常数据
      smartCheckData: [], // 计量超差数量显示
      creditRatingData: [], // 上月报警数量排行
      historyOption: [], // 历史油价
      // smartCheckUpData: [], //智能自检异常表格
      salesRankData: [],
      offsetHeight: 0
    };
  },
  computed: {
    tableHeight() {
      var _h = (this.offsetHeight - 110 - 135 - 74 - 64) / 2;
      _h = _h < 110 ? 110 : _h;
      return _h + 'px';
    },
    lineHeight() {
      var _h = this.offsetHeight - 110 - 135 - 50 - 9;
      _h = _h < 300 ? 300 : _h;
      return _h;
    },
    gasData() {
      return this.$store.getters.gasData;
    }
  },
  created() {
    // console.log('offsetHeight', document.body.offsetHeight);
    this.offsetHeight = document.body.offsetHeight - 70 - 40 - 32;
    this.localCenter = this.$store.state.app.location;

    if (this.$route.query.site_name) {
      this.site_name = this.$route.query.site_name;
    }

    // 基础数据
    this.getSiteData();
    // 历史油价
    this.getHistoryPrice();

    this.getTableData();
  },
  mounted() {
    window.addEventListener(
      'resize',
      () => {
        this.offsetHeight = document.body.offsetHeight - 70 - 40 - 32;
      },
      false
    );

    var that = this;
    Resize.$on('resizeEchart', function() {
      that.getSiteData(true);
    });
  },
  methods: {
    async getSiteData() {
      // var that = this;
      getSiteData()
        .then(response => {
          if (response.code === 0) {
            this.basicData = response.data;
            this.basicWarnData.port_status = response.data.port_status;
          }
        })
        .catch(error => {
          console.log('error', error);
        });

      getWarningCountData()
        .then(response => {
          this.basicWarnData = response.data;
          this.basicWarnData.port_status = this.basicData.port_status;
        })
        .catch(error => {
          console.log('error', error);
        });
    },
    getTableData(params) {
      // 计量超差数量显示
      getLockMachines(params)
        .then(response => {
          if (response.code === 0) {
            this.smartCheckData = response.data;
            this.tableLoadingC = false;
          }
        })
        .catch(error => {
          console.log('error', error);
        });

      // 销量排行TOP5
      getSalesRank(params)
        .then(response => {
          if (response.code === 0) {
            this.salesRankData = response.data;
          }
        })
        .catch(error => {
          console.log('error', error);
        });

      // // 离线报警数量排行
      // getAlarmCountRank(params)
      //   .then(response => {
      //     if (response.code === 0) {
      //       this.lockMachineData = response.data;
      //       this.tableLoadingA = false;
      //     }
      //   })
      //   .catch(error => {
      //     console.log('error', error);
      //   });

      // 加油站诚信等级
      getCreditRating(params)
        .then(response => {
          if (response.code === 0) {
            this.creditRatingData = response.data;
            this.tableLoadingA = false;
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    },
    async getHistoryPrice() {
      getHistoryPrice()
        .then(response => {
          const { code, data } = response;
          if (code === 0 && data) {
            this.historyOption.xAxis = [];
            this.historyOption.yAxis = [];
            this.historyOption.series = [];
            let dates = [];

            let oilList = []; // 油品
            let oilData = {}; // 油品对应的油价
            data.map(item => {
              dates.push(item.date);
              for (var obj in item) {
                let _lIn = obj.lastIndexOf('_');
                let oil = obj.substring(_lIn + 1);
                if (_lIn != -1 && oilList.indexOf(oil) == -1) {
                  oilList.push(oil);
                }
              }
            });
            data.map(item => {
              oilList.forEach(obj => {
                if (!oilData[`max_${obj}data`]) oilData[`max_${obj}data`] = [];
                oilData[`max_${obj}data`].push(item[`max_${obj}`] || '---');

                if (!oilData[`min_${obj}data`]) oilData[`min_${obj}data`] = [];
                oilData[`min_${obj}data`].push(item[`min_${obj}`] || '---');

                if (!oilData[`n_${obj}data`]) oilData[`n_${obj}data`] = [];
                oilData[`n_${obj}data`].push(item[`n_${obj}`] || '---');
              });
            });
            oilList.map((item, index) => {
              this.handleAxis(dates, index, item === '10#' ? `-${item}` : item);
              this.handleData(
                oilData[`max_${item}data`],
                oilData[`min_${item}data`],
                oilData[`n_${item}data`],
                index
              );
            });

            this.historyOption.xAxis[0].show = true;
            this.historyOption.legendData = oilList;
            this.draw();
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    },
    handleData(max, min, n, num) {
      let colors = ['#ffd100', '#2c66ff', '#0cdaab', '#ff2121', '#8d4bbb'];
      let _c =
        num > colors.length - 1 ? colors[num % colors.length] : colors[num];
      this.historyOption.series.push(
        {
          type: 'line',
          data: max,
          name: '最高值',
          xAxisIndex: num,
          yAxisIndex: num,
          smooth: true,
          stack: `as${num}`,
          symbol: 'none',
          lineStyle: {
            opacity: 0
          },
          areaStyle: {
            color: _c,
            opacity: 0.2
          }
        },
        {
          type: 'line',
          data: min,
          name: '最低值',
          xAxisIndex: num,
          yAxisIndex: num,
          smooth: true,
          stack: `as${num}1`,
          symbol: 'none',
          lineStyle: {
            opacity: 0
          },
          areaStyle: {
            color: '#fff',
            opacity: 1
          }
        },
        {
          type: 'line',
          data: n,
          name: '平均值',
          xAxisIndex: num,
          yAxisIndex: num,
          smooth: true,
          symbol: 'none',
          lineStyle: {
            color: _c
          }
          // areaStyle: {
          //   color: '#fff',
          //   opacity: 0
          // }
        }
      );
    },
    handleAxis(data, num, name) {
      this.historyOption.xAxis.push({
        gridIndex: num,
        type: 'category',
        boundaryGap: false,
        data: data,
        axisLabel: {
          textStyle: {
            color: '#2c3e50'
          }
        },
        axisTick: {
          show: false
        },
        show: false
      });
      this.historyOption.yAxis.push({
        gridIndex: num,
        axisLabel: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
        nameTextStyle: {
          color: '#2c3e50'
        },
        name: name,
        nameLocation: 'center',
        nameRotate: 360,
        nameGap: 5,
        color: '#2c3e50',
        offset: 1,
        // splitNumber: 0.5,
        // maxInterval: 0.5,
        scale: true
      });
    },
    draw() {
      this.$nextTick(() => {
        echart(this.$refs['echart-pie'], 'meanLine', this.historyOption);
      });
    }
  }
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';
.panel {
  margin-top: 12px;
}

.home-page {
  padding: 16px;
  padding-top: 0;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

.quick-entry {
  padding: 27px 16px;
  background: #fff;
  border-radius: 8px;
  transition: all 0.5s ease-out;
}

.alarm-meta {
  background: #fff;
  margin-top: 12px;
  display: flex;
  border-radius: 8px;
  transition: all 0.5s ease-out;
}

.el-table--scrollable-y .el-table__body-wrapper {
  padding-right: 20px;
}
.primary {
  color: #f56c6c;
}
.danger {
  color: #01adbe;
}

.sales_item {
  width: calc(100% + 50px);
  padding-right: 35px;
  box-sizing: border-box;
  overflow-y: scroll;
}
</style>
